import * as tf from '../../dist/tfjs.esm';

import { PointwiseConvParams } from './types';

export function pointwiseConvLayer(
  x: tf.Tensor4D,
  params: PointwiseConvParams,
  strides: [number, number],
) {
  return tf.tidy(() => {
    let out = tf.conv2d(x, params.filters, strides, 'same');
    out = tf.add(out, params.batch_norm_offset);
    return tf.clipByValue(out, 0, 6);
  });
}
